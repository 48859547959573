var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"sabc_news_section sabc_news_nhtopstory"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},_vm._l((_vm.news),function(article){return _c('div',{key:article.id,staticClass:"col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12"},[_c('div',{staticClass:"sabc_news_box"},[(article.link.startsWith('http'))?_c('a',{staticClass:"sabc_news_pic",attrs:{"href":article.link,"target":"_blank"}},[_c('img',{attrs:{"srcset":[
                                _vm.$resizeImage(article.image.image_path, 295, 188) + ' 991w',
                                _vm.$resizeImage(article.image.image_path, 361, 188) + ' 768w',
                                _vm.$resizeImage(article.image.image_path, 144, 91) + ' 575w'
                            ].join(', '),"sizes":'(max-width: 480px) 575w, (max-width: 575px) 575w, (max-width: 768px) 768w, (max-width: 991px)  991w',"src":_vm.$resizeImage(article.image.image_path, 144, 91),"width":"295","height":"188","alt":article.headline,"title":article.headline,"loading":"lazy"}}),_vm._v(" "),(article.sport == null)?_c('label',[_vm._v("News")]):(article.sport.name == 'Main Domain')?_c('label',[_vm._v("News")]):(article.sport.name != 'Main Domain' && _vm.type == 'all')?_c('label',[_vm._v(_vm._s(article.sport.name))]):_vm._e()]):_c('router-link',{staticClass:"sabc_news_pic",attrs:{"to":article.link}},[_c('img',{attrs:{"srcset":[
                                _vm.$resizeImage(article.image.image_path, 295, 188) + ' 991w',
                                _vm.$resizeImage(article.image.image_path, 361, 188) + ' 768w',
                                _vm.$resizeImage(article.image.image_path, 144, 91) + ' 575w'
                            ].join(', '),"sizes":'(max-width: 480px) 575w, (max-width: 575px) 575w, (max-width: 768px) 768w, (max-width: 991px)  991w',"src":_vm.$resizeImage(article.image.image_path, 144, 91),"width":"295","height":"188","alt":article.headline,"title":article.headline,"loading":"lazy"}}),_vm._v(" "),(article.sport == null)?_c('label',[_vm._v("News")]):(article.sport.name == 'Main Domain')?_c('label',[_vm._v("News")]):(article.sport.name != 'Main Domain' && _vm.type == 'all')?_c('label',[_vm._v(_vm._s(article.sport.name))]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"sabc_news_desc"},[_c('h3',[(article.link.startsWith('http'))?_c('a',{attrs:{"href":article.link,"target":"_blank"}},[_vm._v(_vm._s(article.headline))]):_c('router-link',{attrs:{"to":article.link}},[_vm._v(_vm._s(article.headline))]),_vm._v(" "),(article.sport == null)?_c('label',[_vm._v("News")]):(article.sport.name == 'Main Domain')?_c('label',[_vm._v("News")]):(article.sport.name != 'Main Domain' && _vm.type == 'all')?_c('label',[_vm._v(_vm._s(article.sport.name))]):_vm._e()],1)])],1)])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }