<template>
    <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <main class="" v-else-if="is_data_ready">
        <!------------------------------------------->
        <TopStories v-if="topStories.length" :topStories="topStories"></TopStories>
        <LatestNewsRow v-if="latestNews.length" :news="latestNews" :type="'all'"></LatestNewsRow>
        <MatchSlider></MatchSlider>
        <section class="sabc_news_section container live_widget_panel">
            <div class="sabc_news_section container widget_live cricket_widget_live" v-if="match_id != 0" :id="match_id">
                <h2>Cricket Live Match</h2>
                <cricketWidget v-if="match_id != 0" :id="match_id" ></cricketWidget>
            </div>
            <div class="sabc_news_section container widget_live soccer_widget_live mb-25" v-if="soccer_match">
                <h2>
                    <a :href="soccer_match.fullslug">{{soccer_match.title}} <i></i></a>
                </h2>
                <iframe class="soccer_widget soccer_widget_iframe" :src="('https://widgets.thesports01.com/en/3d/football?profile=v1qllh2jwioujq7&uuid='+soccer_match.match_animation_id)"></iframe>
            </div>
        </section>
<!--        <Poll v-if="poll != '' && poll != null" :poll="poll"></Poll>-->
        <section class="container sabc_poll_section_mobi" v-if="poll != '' && poll != null">
            <div class="sabc_poll_section">
                <div class="sabc_poll_checklist">
                    <h2>
                        {{poll.question}}
                    </h2>
                    <p>
                        {{poll.description}}
                    </p>
                    <ul>
                        <li v-for="answer in poll.pollAnswers" :key="answer.id">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" :disabled="isDisabled" :checked="checkPollResponse(answer.pollResponse)" type="radio" name="poll_answer" @click="answer_id = answer.id" :id="answer.id" :value="answer.id">
                                <label class="form-check-label" :for="answer.id">{{answer.answer}}</label>
                            </div>
                            <div class="progress" v-if="isDisabled">
                                <div class="progress-bar" role="progressbar" :style="{width:calculatePercentage(poll.poll_response_count,answer.poll_response_count)+'%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                    {{calculatePercentage(poll.poll_response_count,answer.poll_response_count)}}%
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="sabc_poll_ad">
                    <img v-if="poll.image" :src="$resizeImage(poll.image.image_path,300,250)" width="300" height="250" alt="SABC Poll Ads"/>
                </div>
                <div class="sabc_poll_votebtn_dtl">
                    <a v-if="!isDisabled" style="cursor: pointer" href="javascript:;" class="sabc_poll_votebtn" @click="submitPole">Vote</a>
                    <div class="sabc_poll_votedtl">
                        <label>
                            {{(poll.poll_response_count) ? poll.poll_response_count : '0'}} votes so far
                                                    <label><i>|</i>{{poll.remainingDays}} day(s) left</label>
                        </label>
                    </div>
                </div>
            </div>

        </section>
        <ChannelSlider :banner = "banner"></ChannelSlider>
        <Social></Social>
        <NewsRow v-if="latestNewsFootball.length" :type="'Football'" :news="latestNewsFootball"></NewsRow>
        <NewsRow v-if="latestNewsCricket.length" :type="'Cricket'" :news="latestNewsCricket"></NewsRow>
        <NewsRow v-if="latestNewsRugby.length" :type="'Rugby'" :news="latestNewsRugby"></NewsRow>
        <VideoSlider :videos="videos"></VideoSlider>
    </main>
</template>

<script>
    import TopStories from '@/components/common/top-stories'
    import MatchSlider from '@/components/Home/match-slider/match-slider'
    import ChannelSlider from '@/components/Home/channel-slider/channel-slider'
    import NewsRow from '@/components/common/news-row'
    import LatestNewsRow from '@/components/Home/latest-news/news-row'
    import Social from '@/components/common/social'
    import VideoSlider from '@/components/Home/video-slider/video-slider'
    import cricketWidget from '../Cricket/Livecenter/MatchDetail/cricket-widget';

    export default {
        name: 'Home',
        metaInfo() {
            if (this.$store.state.siteSetting) {
                let ss = this.$store.state.siteSetting;

                var replacements = {
                    "%SECTION_TILTE%": this.section.meta_title,
                    "%SECTION_DESCRIPTION%": this.section.meta_description,
                    "%SECTION_KEYWORD%": this.section.meta_keywords,
                    "%SPORT%": this.$route.params.sport
                };

                let title = this.$getSafe(() => this.$replacePlaceHolders(ss.home_page_title,replacements));
                let description = this.$getSafe(() => this.$replacePlaceHolders(ss.home_page_meta_description,replacements));
                let keywords = this.$getSafe(() => this.$replacePlaceHolders(ss.home_page_meta_keywords,replacements));

                // let title = this.$getSafe(() => ss.home_page_title);
                // let description = this.$getSafe(() => ss.home_page_meta_description);
                // let keywords = this.$getSafe(() => ss.home_page_meta_keywords);

                return {
                    title: title,
                    description: description,
                    meta: this.$getMetaTags(title, description, keywords),
                    link: [
                        {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}
                    ],
                }
            }
        },
        data() {
            return {
                editorsPick: [],
                latestNews: [],
                latestNewsCricket: [],
                latestNewsFootball: [],
                latestNewsRugby: [],
                banner: [],
                slider: [],
                topStories: [],
                section: [],
                videos: [],
                poll: '',
                answer_id: '',
                poll_id: '',
                match_id: 0,
                soccer_match: [],
                isDisabled: false,
                options: {
                    question: '',
                    answers: [
                    ],
                }
            }
        },
        components: {
            TopStories, MatchSlider, NewsRow, Social, VideoSlider, ChannelSlider, LatestNewsRow, cricketWidget
        },
        mounted() {
            this.getHomeData();
        },
        serverPrefetch() {
            return this.getHomeData();
        },

        methods: {
            getHomeData() {
                this.is_data_ready = false
                // return this.$axios.get(this.$GetSportsData)
                return this.$prepareAPICall(this.$GetSportsData)
                    .then(response => {
                        if (response.data) {
                            this.editorsPick = response.data.editorsPick;
                            this.latestNews = response.data.latestNewsAll;
                            this.latestNewsCricket = response.data.latestNewsCricket;
                            this.latestNewsFootball = response.data.latestNewsFootball;
                            this.latestNewsRugby = response.data.latestNewsRugby;
                            this.poll = response.data.poll;
                            this.banner = response.data.bannerImages;
                            this.slider = response.data.slider;
                            this.topStories = response.data.topStories;
                            this.section = response.data.section;
                            this.videos = response.data.videos;
                            this.is_data_ready = true;

                            this.soccer_match = [];
                            this.match_id = 0;
                            // Soccer widget setting
                            if(response.data.featured && response.data.featured.soccer){
                                this.soccer_match = response.data.featured.soccer[0];
                                this.soccer_match.fullslug = '/live/football/match-detail/'+this.soccer_match.slug+'/'+this.soccer_match.match_date+'/'+this.soccer_match.match_id+'/overview';
                            }
                            // Cricket widget setting
                            if(response.data.featured && response.data.featured.cricket){
                                this.$route.params.id = this.match_id =  response.data.featured.cricket[0].match_animation_id;
                            }
                        }
                    })
                    .catch(error => {
                    })
            },
            submitPole() {
                if(!this.checkPollResponse(this.poll.pollResponse)) {
                    this.isDisabled = true;
                    this.poll.poll_response_count++;
                    let data = {'poll_id': this.poll.id, 'poll_answer_id': this.answer_id};
                    this.$axios.post(this.$SavePollResponse,data)
                        .then((response) => {
                            this.poll = response.data.response;
                        })
                        .catch()
                }
            },
            checkPollResponse(array) {
                for(var i=0;i<array.length;i++) {
                    if(array[i].ip_address == this.$store.state.ip) {
                        this.isDisabled = true;
                        return true;
                    }
                }
                return false;
            },
            calculatePercentage(total, value) {
                if(value == 0) {
                    return 0;
                } else {
                    return ((value*100)/total).toFixed(2);
                }
            }
        }
    }
</script>
<style lang="scss">
@import '~@/assets/scss/home.scss';
@import '~@/assets/scss/featured.scss';
@import '~@/assets/scss/cricket.scss';
</style>
