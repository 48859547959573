<template>
    <section class="sabc_news_section sabc_news_nhtopstory">
        <div class="container">
            <h2 v-if="type != 'all'">
                <router-link :to="{ name: 'sport_home', params: { sport: type.toLowerCase(), news: 'news'}}">
                    Latest {{(type == 'Football')?'Soccer':type}} News<i></i>
                </router-link>
            </h2>
            <div class="row">
              <!-- <div class="sabc_news_box_inr"> -->
                <div v-for="article in news" class="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12" :key="article.id">
                    <div class="sabc_news_box">
                        <router-link :to="article.articleSection.section.slug+article.slug" class="sabc_news_pic">
                            <img 
                                :srcset="[
                                    $resizeImage(article.image.image_path, 295, 188) + ' 991w',
                                    $resizeImage(article.image.image_path, 361, 188) + ' 768w',
                                    $resizeImage(article.image.image_path, 144, 91) + ' 575w'
                                ].join(', ')"
                                :sizes="'(max-width: 480px) 575w, (max-width: 575px) 575w, (max-width: 768px) 768w, (max-width: 991px)  991w'"
                                :src="$resizeImage(article.image.image_path, 144, 91)" 
                                width="295" 
                                height="188" 
                                :alt="article.headline" 
                                :title="article.headline" 
                                loading="lazy">
                            <label v-if="article.sport.name == 'Main Domain'">News</label>
                            <label v-if="article.sport.name != 'Main Domain' && type == 'all'">{{article.sport.name}}</label>
                        </router-link>
                        <div class="sabc_news_desc">
                            <h3>
                                <router-link :to="article.articleSection.section.slug+article.slug">{{article.headline}}</router-link>
                                <label v-if="article.sport.name == 'Main Domain'">News</label>
                                <label v-if="article.sport.name != 'Main Domain' && type == 'all'">{{article.sport.name}}</label>
                            </h3>
                        </div>

                    </div>
                </div>
              <!-- </div> -->
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        name: 'NewsRow',
        jsonld() {
            if (isServer)
                return {
                    "@context": "http://schema.org",
                    "@graph": []
                }

            if (!this.news)
                return

            let events = [];
            this.news.forEach((article) => {
                let path = this.$siteURL.replace(/\/+$/, '') + article.articleSection.section.slug+article.slug;
                events.push(
                    {
                        '@type': 'NewsArticle',
                        mainEntityOfPage: {
                            '@type': 'WebPage',
                            '@id': path
                        },
                        headline:  article.headline,
                        description: article.meta_description,
                        image:  article.image.image_path,
                        author: {
                            '@type': 'Person',
                            name: article.author.display_name,
                            "url" : this.$siteURL
                        },
                        publisher: {
                            '@type': 'Organization',
                            name: "SABC",
                            logo: {
                                '@type': 'ImageObject',
                                url: this.$siteURL + 'public/images/logo.png'
                            }
                        },
                        datePublished: article.created_at,
                        dateModified: article.updated_at
                    }
                )
            });
            return {
                "@context": "http://schema.org",
                "@graph": events
            };
        },
        props: ['news','type'],
        data() {
            return {}
        },
        mounted() {},
        methods: {}
    }
</script>
